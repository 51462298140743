/**
 * Mapping of language tag to language name.
 * See "List of supported languages" on https://developer.tomtom.com/map-display-api/documentation/vector/tile
 */
export enum LanguagesGenesis {
  'ngt' = 'Neutral Ground Truth',

  /**
   * controlled by `latin` Boolean:
   */
  // 'ngt-Latn' = 'Neutral Ground Truth - Latin exonyms',

  'ar' = 'Arabic',
  'bg-BG' = 'Bulgarian',
  'zh-TW' = 'Chinese (Taiwan)',
  'zh-CN' = 'Chinese (Simplified)',
  'cs-CZ' = 'Czech',
  'da-DK' = 'Danish',
  'nl-NL' = 'Dutch',
  'en-AU' = 'English (Australia)',
  'en-CA' = 'English (Canada)',
  'en-GB' = 'English (Great Britain)',
  'en-NZ' = 'English (New Zealand)',
  'en-US' = 'English (USA)',
  'fi-FI' = 'Finnish',
  'fr-FR' = 'French',
  'de-DE' = 'German',
  'el-GR' = 'Greek',
  'hu-HU' = 'Hungarian',
  'id-ID' = 'Indonesian',
  'it-IT' = 'Italian',
  'ko-KR' = 'Korean',
  'lt-LT' = 'Lithuanian',
  'ms-MY' = 'Malay',
  'nb-NO' = 'Norwegian',
  'pl-PL' = 'Polish',
  'pt-BR' = 'Portuguese (Brazil)',
  'pt-PT' = 'Portuguese (Portugal)',
  'ru-RU' = 'Russian',

  /**
   * controlled by `latin` Boolean:
   */
  // 'ru-Latn-RU' = 'Russian - Latin script',

  'sk-SK' = 'Slovak',
  'sl-SI' = 'Slovenian',
  'es-ES' = 'Spanish (Castilian)',
  'es-MX' = 'Spanish (Mexico)',
  'sv-SE' = 'Swedish',
  'th-TH' = 'Thai',
  'tr-TR' = 'Turkish',
}

/**
 * Mapping of language tag to language name.
 * See "List of supported languages" on https://developer.tomtom.com/map-display-api/documentation/maps-platform/supported-languages
 */
export enum LanguagesOrbis {
  'ngt' = 'Neutral Ground Truth',

  /**
   * controlled by `latin` Boolean:
   */
  // 'ngt-Latn' = 'Neutral Ground Truth - Latin exonyms',

  'ar' = 'Arabic',
  'eu' = 'Basque',
  'bg' = 'Bulgarian',
  'ca' = 'Catalan',
  'zh-Hant' = 'Chinese (Taiwan)',
  'zh-Hans' = 'Chinese (Simplified)',
  'hr' = 'Croatian',
  'cs' = 'Czech',
  'da' = 'Danish',
  'nl' = 'Dutch',
  'en' = 'English',
  'et' = 'Estonian',
  'fi' = 'Finnish',
  'fr' = 'French',
  'gl' = 'Galician',
  'de' = 'German',
  'el' = 'Greek',
  'he' = 'Hebrew',
  'hi' = 'Hindi',
  'hu' = 'Hungarian',
  'id' = 'Indonesian',
  'it' = 'Italian',
  'ja' = 'Japanese',
  'kk' = 'Kazakh',
  'ko' = 'Korean',

  /**
   * controlled by `latin` Boolean:
   */
  // 'ko-Latn' = 'Korean - Latin script',

  'lv' = 'Latvian',
  'lt' = 'Lithuanian',
  'ms' = 'Malay',
  'nb' = 'Norwegian',
  'pl' = 'Polish',
  'pt' = 'Portuguese',
  'po' = 'Romanian',
  'ru' = 'Russian', // Cyrillic script

  /**
   * controlled by `latin` Boolean:
   */
  // 'ru-Latn' = 'Russian - Latin script',

  'sr' = 'Serbian', // Cyrillic script

  /**
   * controlled by `latin` Boolean:
   */
  // 'sr-Latn' = 'Serbian - Latin script',

  'sk' = 'Slovak',
  'sl' = 'Slovenian',
  'es' = 'Spanish',
  'sv' = 'Swedish',
  'th' = 'Thai',
  'tr' = 'Turkish',
  'uk' = 'Ukrainian', // Cyrillic script

  /**
   * controlled by `latin` Boolean:
   */
  // 'uk-Latn' = 'Ukrainian - Latin script',
}

export const supportedLanguagesGenesis: (keyof typeof LanguagesGenesis)[] = [
  'ngt',
  'ar',
  'bg-BG',
  'zh-TW',
  'zh-CN',
  'cs-CZ',
  'da-DK',
  'nl-NL',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-NZ',
  'en-US',
  'fi-FI',
  'fr-FR',
  'de-DE',
  'el-GR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ko-KR',
  'lt-LT',
  'ms-MY',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'es-ES',
  'es-MX',
  'sv-SE',
  'th-TH',
  'tr-TR',
];

export const supportedLanguagesOrbis: (keyof typeof LanguagesOrbis)[] = [
  'ngt',
  'ar',
  'bg',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'eu',
  'fi',
  'fr',
  'gl',
  'he',
  'hi',
  'hr',
  'hu',
  'id',
  'it',
  'ja',
  'kk',
  'ko',
  'lt',
  'lv',
  'ms',
  'nb',
  'nl',
  'pl',
  'po',
  'pt',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'zh-Hans',
  'zh-Hant',
];
