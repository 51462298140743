import React from 'react';
import styled from 'styled-components';
import { tombac } from 'tombac';
import { MapTypes } from 'reducers/menuReducer';
import { MapMenuButton, ZoomInButton } from '../UI/UI';
import { MapControlsButtonsContainer } from '../UI/MapControls/MapControlsButtonsContainer';
import { MapStyles, MapStylesMenuContainer } from './MapStyle/MapStyles';
import { centerOnRegions } from './mapUtils';
import { RegionDto } from '../../model/RegionDto';
import mapStyleIcon from './icons/map-style.svg';

const Divider = styled.div`
  width: calc(100% + 30px);
  margin-left: -15px;
  height: 1px;
  background-color: ${tombac.color('neutral', 400)};
`;

interface Props {
  map?: React.MutableRefObject<mapboxgl.Map | undefined>;
  mapStyleMenuContent?: React.ReactNode;
  mapTypeName: MapTypes;
  zoomInButton?: {
    regions: RegionDto[];
    type: React.ComponentProps<typeof ZoomInButton>['type'];
  };
  children?: React.ReactNode;
}

/**
 * Container with control buttons which is placed underneath Zoom In / Zoom Out / Bearing icons in top right corner of the map.
 */
export const MapControls: React.FC<Props> = ({
  map,
  mapStyleMenuContent,
  mapTypeName,
  zoomInButton,
  children,
}) => {
  const handleZoomInButtonClick = React.useCallback(() => {
    if (!map?.current || !zoomInButton) {
      return;
    }
    centerOnRegions(map.current, zoomInButton.regions);
  }, [map, zoomInButton?.regions]);

  return (
    <MapControlsButtonsContainer>
      <MapMenuButton icon={mapStyleIcon}>
        <MapStyles mapTypeName={mapTypeName} />
        {mapStyleMenuContent ? (
          <>
            <Divider />
            <MapStylesMenuContainer>
              {mapStyleMenuContent}
            </MapStylesMenuContainer>
          </>
        ) : null}
      </MapMenuButton>
      {zoomInButton && zoomInButton.regions.length > 0 ? (
        <ZoomInButton
          onClick={handleZoomInButtonClick}
          type={zoomInButton.type}
        />
      ) : null}
      {children}
    </MapControlsButtonsContainer>
  );
};
