import AnalysisApi, { RequestListParams } from 'api/AnalysisApi';
import DraftApi, { AnalysisDraft } from 'api/DraftApi';
import { Loading } from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounced';
import analysisTypeName from 'logic/analysis/analysisTypeName';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Star from 'react-feather/dist/icons/star';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import {
  Box,
  Button,
  Caption,
  Heading,
  Input,
  NavbarMenu,
  NavbarMenuItem,
  tombac,
  useToasts,
} from 'tombac';
import { DeleteIcon, DraftIcon, SearchIcon, SpinnerIcon } from 'tombac-icons';
import { useSettings, useUser } from 'user';
import {
  AnalysisInfo,
  AnalysisPage,
  AnalysisStatus,
} from '../../model/AnalysisDto';
import { formatDate } from '../AnalysisNew/DateRanges/DateUtils';
import Title from '../Layout/Title';
import { Delayed } from '../UI/Delayed';
import { Spacer } from '../UI/FormUI';
import AnalysisListItem from './AnalysisListItem';
import './AnalysisTable.css';
import { Pagination } from './Pagination';
import ReportsTrialBox from './ReportsTrialBox';
import { SimpleRadioGroup } from './SimpleRadioGroup';
import StatusFilter from './StatusFilter';
import { ReactComponent as NoReportsImg } from 'svg/empty-results.svg';
import { NavBarLink } from 'components/UI/UI';
import AnalysisCancelationModal from './AnalysisCancelationModal';

function DraftItem({ draft }: { draft: AnalysisDraft }) {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [, , { continueFromDraft }] = useMenu();
  const history = useHistory();
  const settings = useSettings();

  const restoreDraft = async () => {
    const fullDraft = await DraftApi.get(draft.id, draft.type);
    addToast('Restored from draft', 'success');
    continueFromDraft(fullDraft);
    history.push('/new/dates');
  };

  return (
    <div className="DraftItem" onClick={restoreDraft}>
      <div>
        <div className="DraftItem__type">{analysisTypeName(draft.type)}</div>
        <div className="DraftItem__name">{draft.name}</div>
      </div>
      <div className="DraftItem__date">
        {formatDate(draft.creationTime, settings?.dateFormat)}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Button
          size="s"
          shape="circle"
          $background="transparent"
          variant="flat"
          onClick={async () => {
            await DraftApi.deleteDraft(draft.id, queryClient);
            addToast(`Draft ${draft.name} deleted`);
          }}
        >
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
}

interface DraftsListProps {
  drafts: AnalysisDraft[] | undefined;
}

const DraftsList: React.FC<DraftsListProps> = ({ drafts }) => {
  if (drafts === undefined) {
    return <Loading />;
  }

  return (
    <>
      {drafts.length === 0 ? (
        <NoResultsFound
          subtitle={
            <>
              Your drafts will appear here. Create drafts by clicking '
              <DraftIcon /> Save as Draft' button while creating your report
            </>
          }
        />
      ) : (
        <>
          <div className="DraftsList__header">
            <div>Name</div>
            <div>Created on</div>
          </div>
          {drafts === undefined && <Loading />}
          {drafts !== undefined &&
            drafts.map((it) => <DraftItem draft={it} key={it.id} />)}
        </>
      )}
    </>
  );
};

type ListType = 'reports' | 'drafts' | 'archived';
interface AnalysisTablePageProps {
  path?: string;
  title?: string;
  adminView?: boolean;
  showDrafts?: boolean;
}
const AnalysisTablePage: React.FC<AnalysisTablePageProps> = ({
  path = '/dashboard',
  title = 'My reports',
  adminView = false,
  showDrafts = true,
}) => {
  const location = useLocation();
  const [listType, setListType] = useState<ListType>(
    location.pathname === `${path}/drafts` && showDrafts ? 'drafts' : 'reports',
  );

  const [cancelAnalysisModal, setCancelAnalysisModal] = useState<{
    name: string;
    id: number;
  } | null>(null);

  const cancel = async (id: number) => {
    await analysisPage.cancel(id);
    setCancelAnalysisModal(null);
  };
  const openCancelModal = (name: string, id: number) => {
    setCancelAnalysisModal({ name, id });
  };
  const closeModal = () => {
    setCancelAnalysisModal(null);
  };

  const drafts = DraftApi.useDrafts();
  const analysisPage = useAnalysisPage({
    adminView,
    archivedOnly: false,
    refetchInterval: listType === 'reports' ? 5000 : undefined,
  });
  const analysisPageArchived = useAnalysisPage({
    adminView,
    archivedOnly: true,
  });
  const navCounters = useMemo(
    (): AnalysisNavbarCounters => ({
      reports: analysisPage.data?.numberOfAllAnalyses ?? 0,
      drafts: drafts?.length ?? 0,
      archived: analysisPageArchived.data?.numberOfAllAnalyses ?? 0,
    }),
    [
      analysisPage.data?.numberOfAllAnalyses,
      analysisPageArchived.data?.numberOfAllAnalyses,
      drafts?.length,
    ],
  );

  const handleListTypeChange = useCallback(
    (listType: ListType) => {
      setListType(listType);
      for (const page of [analysisPage, analysisPageArchived]) {
        page.setPage(1);
        page.setCurrentTab('All');
      }
    },
    [analysisPage, analysisPageArchived],
  );

  return (
    <div className="AnalysisTable__container">
      {cancelAnalysisModal && (
        <AnalysisCancelationModal
          onClose={closeModal}
          onCancel={() => cancel(cancelAnalysisModal.id)}
          analysisName={cancelAnalysisModal.name}
          analysisId={cancelAnalysisModal.id}
        />
      )}
      <div className="AnalysisTable__content">
        <Title>{title}</Title>
        <Heading level={3} fontWeight="bold">
          {title}
        </Heading>
        {listType === 'reports' ? <ReportsTrialBox /> : null}
        <AnalysisNavBar
          path={path}
          listType={listType}
          onChange={handleListTypeChange}
          showDrafts={showDrafts}
          counters={navCounters}
        />
        {listType === 'reports' ? (
          <AnalysisTable
            analysisPage={analysisPage}
            openCancelModal={openCancelModal}
            archived={false}
            adminView={adminView}
          />
        ) : null}
        {listType === 'drafts' ? <DraftsList drafts={drafts} /> : null}
        {listType === 'archived' ? (
          <AnalysisTable
            analysisPage={analysisPageArchived}
            openCancelModal={openCancelModal}
            archived={true}
            adminView={adminView}
          />
        ) : null}
      </div>
    </div>
  );
};

type AnalysisPageTab = 'All' | 'Favorite';
enum QueryKeys {
  Reports = 'analysis-list',
  ArchivedReports = 'analysis-archived-list',
}
interface AnalysisPageResponse {
  page: number;
  data?: AnalysisPage;
  isFetching: boolean;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setFavorite: (analysisId: number, favorite: boolean) => void;
  cancel: (id: number) => Promise<void>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  statusFilter: Set<AnalysisStatus>;
  setStatusFilter: React.Dispatch<React.SetStateAction<Set<AnalysisStatus>>>;
  currentTab: AnalysisPageTab;
  setCurrentTab: React.Dispatch<React.SetStateAction<AnalysisPageTab>>;
  archive: (analysisId: number) => void;
  restore: (analysisId: number) => void;
}
export const useAnalysisPage = ({
  adminView,
  archivedOnly,
  refetchInterval,
}: {
  adminView?: boolean;
  archivedOnly?: boolean;
  refetchInterval?: number;
}): AnalysisPageResponse => {
  const key = archivedOnly ? QueryKeys.ArchivedReports : QueryKeys.Reports;
  const { addToast } = useToasts();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [filter, setFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<Set<AnalysisStatus>>(
    new Set(),
  );
  const [currentTab, setCurrentTab] = useState<AnalysisPageTab>('All');

  const debouncedFilter = useDebounce(filter, 300);

  const params = useMemo<RequestListParams>(
    () => ({
      page,
      pageSize,
      favoriteOnly: currentTab === 'Favorite',
      archivedOnly: archivedOnly ?? false,
      ownedOnly: !adminView,
      filter,
      statusFilterSet: statusFilter,
    }),
    [page, debouncedFilter, statusFilter, currentTab, archivedOnly],
  );
  const { data, isLoading, isFetching } = useQuery(
    [key, params],
    () => AnalysisApi.getList(params),
    { keepPreviousData: true, refetchInterval },
  );

  useEffect(() => {
    if (page > data?.numberOfPages) {
      setPage(1);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const setFavorite = (analysisId: number, favorite: boolean) => {
    const apiFn = favorite ? AnalysisApi.fave : AnalysisApi.unfave;
    apiFn(analysisId).then(() => queryClient.invalidateQueries(key));
    queryClient.setQueryData([key, params], (old: AnalysisPage) => {
      const newInfos = old.analysisInfos.map((it) => {
        if (it.id === analysisId) {
          return { ...it, favorite };
        }
        return it;
      });
      return { ...old, analysisInfos: newInfos };
    });
  };

  const archive = (analysisId: number) => {
    addToast('Archived analysis', 'success');
    AnalysisApi.archive(analysisId).then(() => {
      queryClient.invalidateQueries(QueryKeys.Reports);
      queryClient.invalidateQueries(QueryKeys.ArchivedReports);
    });
  };

  const restore = (analysisId: number) => {
    addToast('Restored analysis', 'success');
    AnalysisApi.restore(analysisId).then(() => {
      queryClient.invalidateQueries(QueryKeys.Reports);
      queryClient.invalidateQueries(QueryKeys.ArchivedReports);
    });
  };

  const cancel = async (id: number) => {
    await AnalysisApi.cancel(id);
    queryClient.invalidateQueries(key);
  };

  return {
    page,
    data,
    isFetching,
    isLoading,
    setPage,
    pageSize,
    setPageSize,
    setFavorite,
    cancel,
    filter,
    setFilter,
    setStatusFilter,
    statusFilter,
    currentTab,
    setCurrentTab,
    archive,
    restore,
  };
};

type AnalysisNavbarCounters = { [key in ListType]: number };
interface AnalysisNavbarProps {
  path?: string;
  listType: ListType;
  onChange: (listType: ListType) => void;
  showDrafts?: boolean;
  counters: AnalysisNavbarCounters;
}
export const AnalysisNavBar: React.FC<AnalysisNavbarProps> = ({
  path,
  listType,
  onChange,
  showDrafts = true,
  counters,
}) => {
  const handleClick = useCallback((type: ListType) => () => onChange(type), [
    onChange,
  ]);

  const items: {
    name: string;
    path: string;
    counter: number;
    listType: ListType;
    show: boolean;
  }[] = [
    {
      name: 'Reports',
      path: path,
      counter: counters.reports,
      listType: 'reports',
      show: true,
    },
    {
      name: 'Drafts',
      path: `${path}/drafts`,
      counter: counters.drafts,
      listType: 'drafts',
      show: showDrafts,
    },
    {
      name: 'Archived',
      path: path,
      counter: counters.archived,
      listType: 'archived',
      show: true,
    },
  ];

  return (
    <div className="AnalysisTable__navbar">
      <NavbarMenu breakpoint={1050} className="AnalysisTable__navbar__list">
        {items.map((item) =>
          item.show ? (
            <NavbarMenuItem
              key={item.listType}
              className="AnalysisTable__navbar__item"
            >
              <NavBarLink
                to={item.path}
                className={`AnalysisTable__navbar__link${
                  listType === item.listType ? ' active' : ''
                }`}
                onClick={handleClick(item.listType)}
              >
                {item.name}{' '}
                <Caption
                  $color={tombac.color('neutral', 700)}
                  $paddingLeft="4px"
                >
                  ({item.counter})
                </Caption>
              </NavBarLink>
            </NavbarMenuItem>
          ) : null,
        )}
      </NavbarMenu>
    </div>
  );
};

interface NoResultsFoundProps {
  subtitle?: string | React.ReactNode;
}
export const NoResultsFound: React.FC<NoResultsFoundProps> = ({ subtitle }) => {
  return (
    <div className="analysis-list-empty">
      <h3 className="empty-title">No results found</h3>
      {subtitle ? <p className="empty-description">{subtitle}</p> : null}
      <NoReportsImg />
    </div>
  );
};

interface AnalysisTableProps {
  analysisPage: AnalysisPageResponse;
  openCancelModal: (name: string, id: number) => void;
  archived?: boolean;
  adminView?: boolean;
}
export const AnalysisTable: React.FC<AnalysisTableProps> = ({
  analysisPage,
  openCancelModal,
  archived,
  adminView,
}) => {
  const user = useUser();
  const history = useHistory();

  const tableHeader = (
    <div className="analysis-item analysis-list-header">
      <div className="analysis-item-name-wrapper">Report name</div>
      <div className="analysis-item-status">Status</div>
      <div className="analysis-item-date">Created on</div>
      <div className="analysis-item-duration">Duration</div>
      <div
        className="analysis-more"
        style={{ marginLeft: 'auto', marginRight: '0' }}
      />
    </div>
  );

  if (analysisPage.data === undefined) {
    return <Loading />;
  }

  const fromCount = (analysisPage.page - 1) * analysisPage.pageSize + 1;
  const toCount = Math.min(
    analysisPage.page * analysisPage.pageSize,
    analysisPage.data.numberOfAllAnalyses,
  );
  const totalCount = analysisPage.data.numberOfAllAnalyses;
  const list = analysisPage.data;

  const noFilters = analysisPage.filter.length === 0;

  return (
    <Box>
      <div className="analysis-list-filters">
        <Input
          placeholder="Search reports"
          onChange={(e) => analysisPage.setFilter(e.target.value)}
          value={analysisPage.filter}
          data-test="search-reports"
          $width="300px"
          prepend={<SearchIcon color="#ccc" />}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <StatusFilter
          statusFilterSet={analysisPage.statusFilter}
          setStatusFilterSet={analysisPage.setStatusFilter}
        />
        {!archived ? (
          <SimpleRadioGroup
            $ml="3sp"
            value={analysisPage.currentTab}
            options={['All', 'Favorite'] as AnalysisPageTab[]}
            onChange={(favoriteFilter) => {
              analysisPage.setPage(1);
              analysisPage.setCurrentTab(favoriteFilter);
            }}
          />
        ) : null}

        {analysisPage.isFetching && (
          <Delayed timeoutMs={300}>
            <Spacer />
            <SpinnerIcon spin size="lg" />
          </Delayed>
        )}
      </div>
      <div className="AnalysisTable-wrapper">
        {list.numberOfPages > 0 && (
          <Pagination
            page={analysisPage.page - 1}
            pageCount={list.numberOfPages}
            onChange={(e) => analysisPage.setPage(e.selected + 1)}
            fromCount={fromCount}
            toCount={toCount}
            totalCount={totalCount}
          />
        )}
        <div data-test-id="AnalysisList" className="analysis-list">
          {list.analysisInfos.length > 0 && tableHeader}
          {list.analysisInfos.map((analysis: AnalysisInfo, i: number) => (
            <div
              onClick={(e) => {
                const url = analysisUrl(analysis.type, analysis.id);
                if (e.ctrlKey || e.metaKey) {
                  window.open(url, '_blank');
                } else {
                  history.push(url);
                }
              }}
              key={i}
            >
              <AnalysisListItem
                analysis={analysis}
                canCancel={true}
                showOwner={adminView}
                userRole={user?.role}
                isArchived={archived}
                openCancelModal={() =>
                  openCancelModal(analysis.name, analysis.id)
                }
                removeAsFavorite={() =>
                  analysisPage.setFavorite(analysis.id, false)
                }
                archive={() => analysisPage.archive(analysis.id)}
                restore={() => analysisPage.restore(analysis.id)}
                markAsFavorite={() =>
                  analysisPage.setFavorite(analysis.id, true)
                }
              />
            </div>
          ))}
          {((analysisPage.currentTab === 'All' && noFilters) || !noFilters) &&
          list.analysisInfos.length === 0 ? (
            <NoResultsFound subtitle="Try adjusting filters to find reports" />
          ) : null}
          {noFilters &&
          analysisPage.currentTab === 'Favorite' &&
          list.analysisInfos.length === 0 ? (
            <div className="analysis-list-empty">
              <h3 className="empty-title">No favorite reports</h3>
              <p className="empty-description">
                Use <Star size={20} /> button to mark analysis as favorite
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </Box>
  );
};

export default AnalysisTablePage;
