import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 143px;
  right: 16px;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 7px;
`;

interface Props {
  children?: React.ReactNode;
}
/**
 * Container which is placed underneath Zoom In / Zoom Out / Bearing icons in top right corner of the map.
 */
export const MapControlsButtonsContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};
