import { tombac } from 'tombac';
import { MapTypes } from 'reducers/menuReducer';
import {
  IconImage,
  Lines,
  Source,
  Symbols,
} from 'components/AnalysisNew/SelectedLink/MapboxComponents';
import { SelectedLinkFeature } from 'components/AnalysisNew/SelectedLink/SelectedLinkFeature';
import { getFirstLabelLayer, queryFeatures } from 'components/Map/mapUtils';
import useMap from 'hooks/useMap';
import { useMapEvent } from 'hooks/useMapEvent';
import { useMapSymbolVisibility } from 'hooks/useMapSymbolVisibility';
import * as React from 'react';
import arrowTriangleAlpha from './arrow-triangle-alpha.svg';
import { useColorfullArrows } from './colorfullArrows';
import { LinkInfo } from './SelectedLinkInfo/LinkInfo';
import { LinkNode } from './LinkNode';
import { PaletteScale } from './palette/ColorPalette';
import { SelectedLinkUnit, View } from './SelectedLinkPage';
import { useTreeRenderer } from './useTreeRenderer';
import { LinkRegionInfo } from './SelectedLinkInfo/LinkRegionInfo';
import { EntrancesExistsInfo } from './SelectedLinkInfo/EntrancesExitsInfo';
import styled from 'styled-components';

const SegmentsContainer = styled.div`
  position: absolute;
  top: ${tombac.space(2)};
  right: 50px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: 0;
  max-height: 60vh;
  width: 380px;
  overflow-y: auto;
  border-radius: 10px;
`;

export const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${tombac.space(1.5)};
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 360px;
`;

export const scale = PaletteScale.makeMinMax(0, 100, 10);

export type SelectedSegment = {
  segmentHash: string;
};

export interface NodeFilter {
  exclude: Set<LinkNode>;
}

interface Props {
  mapTypeName: MapTypes;
  tree: LinkNode;
  minPercent: number;
  minTrips: number;
  unit: SelectedLinkUnit;
  selectedSegments: SelectedSegment[];
  getColor: (value: number) => string;
  setSelectedSegments: (segments: SelectedSegment[]) => void;
  isSelectedLinkRegion: boolean;
  view: View;
}

export const SelectedLinkTree: React.FC<Props> = ({
  mapTypeName,
  tree,
  minPercent,
  minTrips,
  unit,
  selectedSegments,
  setSelectedSegments,
  getColor,
  isSelectedLinkRegion,
  view,
}) => {
  const [nodeFilter, setNodeFilter] = React.useState<NodeFilter>({
    exclude: new Set(),
  });
  const [hover, setHover] = React.useState({
    nodes: new Set() as Set<LinkNode>,
  });
  const [id] = React.useState(() => String(Math.random()));
  const map = useMap();

  const shouldShowNode = React.useCallback(
    (node: LinkNode, ignoreHighligted = false) => {
      const withinFilter = (node: LinkNode) => {
        if (
          (unit === SelectedLinkUnit.Percents &&
            node.trips / tree.trips < minPercent / 100) ||
          (unit === SelectedLinkUnit.Trips && node.trips < minTrips)
        ) {
          return false;
        }
        return true;
      };
      if (!withinFilter(node)) return false;

      const selected = selectedSegments.map((it) => it.segmentHash);

      let paths: number[][] = selected
        .flatMap((it) => tree.getByHash(it))
        .filter((it) => withinFilter(it))
        .filter((node) => {
          if (ignoreHighligted) return true;

          const isExcluded = nodeFilter.exclude.has(node);
          return !isExcluded;
        })
        .map((it) => it.path);

      if (paths.length > 0) {
        return (
          paths.find((it) => LinkNode.isSameBranch(node.path, it)) !== undefined
        );
      }

      return !isSelectedLinkRegion || selected.length === 0;
    },
    [minTrips, minPercent, unit, tree, selectedSegments, nodeFilter],
  );

  const {
    allLinks,
    icons,
    links,
    selectedSegmentsGeometry,
    selectedLink,
    hoverSegments,
  } = useTreeRenderer({
    root: tree,
    getColor,
    unit,
    shouldShowNode: shouldShowNode,
    selectedSegments,
    hover,
  });

  useMapEvent('click', (e) => {
    const features = queryFeatures(e, ['background-links' + id]);

    let selection: SelectedSegment[] = [];

    let allPaths = [];

    features.forEach((it) => {
      // Check if the clicked path is already selected
      const paths = tree
        .getByHash(it.feature.properties.hash)
        .map((it) => it.path);

      const isSameBranch = paths.some((path) =>
        allPaths.some((it) => LinkNode.isSameBranch(it, path)),
      );
      if (isSameBranch) return;

      allPaths.push(...paths);
      selection.push({
        segmentHash: it.feature.properties.hash,
      });
    });
    if (selection.length > 0) {
      setSelectedSegments(selection);
    }

    setNodeFilter({ exclude: new Set() });
  });

  useMapEvent('mousemove', (e) => {
    let features = queryFeatures(e, ['background-links' + id]);

    map.getCanvas().style.cursor = features.length > 0 ? 'pointer' : '';

    if (features.length === 0) {
      setHover({ nodes: new Set() });
      return;
    }

    const nodes = tree.getByHash(features[0].feature.properties.hash);
    setHover({ nodes: new Set(nodes) });
  });

  useMapSymbolVisibility({ poi: false, shields: true });

  useColorfullArrows(getColor);

  const firstLabelLayerId = React.useMemo(
    () => getFirstLabelLayer(map, 'road-labels', mapTypeName),
    [map, mapTypeName],
  );

  return (
    <>
      <SegmentsContainer>
        {isSelectedLinkRegion && (
          <EntrancesExistsInfo
            unit={unit}
            getColor={getColor}
            tree={tree}
            selectedSegments={selectedSegments}
            view={view}
          />
        )}
        {selectedSegments.length > 0 && (
          <>
            {isSelectedLinkRegion ? (
              <LinkRegionInfo
                selectedSegments={selectedSegments}
                setSelectedSegments={setSelectedSegments}
                setNodeFilter={setNodeFilter}
                nodeFilter={nodeFilter}
                setHover={setHover}
                tree={tree}
                getColor={getColor}
                unit={unit}
                shouldShowNode={shouldShowNode}
                view={view}
              />
            ) : (
              <LinkInfo
                selectedSegments={selectedSegments}
                setSelectedSegments={setSelectedSegments}
                setNodeFilter={setNodeFilter}
                nodeFilter={nodeFilter}
                setHover={setHover}
                tree={tree}
                getColor={getColor}
                unit={unit}
                shouldShowNode={shouldShowNode}
              />
            )}
          </>
        )}
      </SegmentsContainer>
      <Source data={allLinks}>
        <Lines
          id={'background-links' + id}
          line-color="rgba(210,210,210,0.8)"
          line-width={2}
          beforeId={firstLabelLayerId}
        />
      </Source>
      <Source data={hoverSegments}>
        <Lines
          line-color="rgba(210,210,210,0.8)"
          line-width={22}
          line-cap="round"
          beforeId={firstLabelLayerId}
        />
      </Source>
      <Source data={links} generateId>
        <IconImage id="arrow-triangle-alpha" src={arrowTriangleAlpha} />
        <Lines
          line-cap="round"
          line-color={['get', 'line-color']}
          line-width={['get', 'line-width']}
        />
        <Symbols
          icon-size={[
            'max',
            ['min', ['/', ['get', 'line-width'], 8], 1.0],
            0.6,
          ]}
          icon-image="arrow-triangle-alpha"
          symbol-avoid-edges
          symbol-placement="line"
          symbol-spacing={20}
        />
        <Symbols
          text-field="{text}"
          symbol-placement="line-center"
          text-font={['Noto-Bold']}
          text-letter-spacing={0.05}
          text-size={['get', 'text-size']}
          text-color={['get', 'text-color']}
          text-halo-color={['get', 'text-halo-color']}
          text-halo-width={1.5}
        />
      </Source>
      <Source data={icons}>
        <Symbols
          icon-image={['get', 'icon-image']}
          icon-size={['get', 'icon-size']}
          icon-rotate={['get', 'icon-rotate']}
        />
      </Source>
      {!isSelectedLinkRegion && <SelectedLinkFeature links={[selectedLink]} />}
      {(selectedSegmentsGeometry as any[]).map((it, i) => (
        <SelectedLinkFeature links={[it]} key={i} />
      ))}
    </>
  );
}
