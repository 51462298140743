import React from 'react';
import styled from 'styled-components';
import { FormGroup, Heading, Select, Text, tombac } from 'tombac';
import { MapTypes } from '../../../reducers/menuReducer';
import {
  MapStyleSettingsState,
  useMapStyleSettings,
} from '../../../reducers/mapStyleSettingsReducer';
import { MapMenuToggle } from '../../UI/MapMenu/MapMenuToggle';
import { isLatinAvailable } from '../MapStyle/utils';
import {
  LanguagesGenesis,
  LanguagesOrbis,
  supportedLanguagesGenesis,
  supportedLanguagesOrbis,
} from '../../../model/MapStyle';
import * as Icons from '../icons';

interface StyleEntry {
  title: MapStyleSettingsState['style'];
  image: string;
}

interface DropdownOption<
  T = keyof typeof LanguagesGenesis | keyof typeof LanguagesOrbis
> {
  value: T;
  label: string;
}

const styleEntries: StyleEntry[] = [
  { title: 'Street light', image: Icons.StreetLightThumbnail },
  { title: 'Street dark', image: Icons.StreetDarkThumbnail },
  { title: 'Mono light', image: Icons.MonoLightThumbnail },
  { title: 'Mono dark', image: Icons.MonoDarkThumbnail },
  // Hidden until legal confirms we can show it.
  // { title: 'Satellite', image: Icons.SatelliteThumbnail },
];

export const MapStylesMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  row-gap: 12px;
`;

const StylesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 10px;
`;

const Thumbnail = styled.img`
  border-radius: 10px;
  border: 2px solid transparent;
  box-sizing: border-box;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  &.selected {
    border-color: ${tombac.color('accent', 500)};
  }
`;

const MapStyleLabel = styled(Text)`
  text-align: center;
`;

const SelectFormGroup = styled(FormGroup)`
  & label {
    font-size: 14px;
  }
`;

const languageGenesisOptions: DropdownOption<
  keyof typeof LanguagesGenesis
>[] = supportedLanguagesGenesis.map((lang) => ({
  value: lang,
  label: LanguagesGenesis[lang] ?? lang,
}));

const languageOrbisOptions: DropdownOption<
  keyof typeof LanguagesOrbis
>[] = supportedLanguagesOrbis.map((lang) => ({
  value: lang,
  label: LanguagesOrbis[lang] ?? lang,
}));

interface Props {
  mapTypeName: MapTypes;
}

/**
 * TomTom base map styles selection.
 */
export const MapStyles: React.FC<Props> = ({ mapTypeName }) => {
  const [mapStyleSettings, setMapStyleSettings] = useMapStyleSettings();

  const selectedLanguageGenesisOption = React.useMemo(
    (): DropdownOption<keyof typeof LanguagesGenesis> | undefined =>
      languageGenesisOptions.find(
        (o) => o.value === mapStyleSettings.languageGenesis,
      ),
    [mapStyleSettings.languageGenesis],
  );

  const selectedLanguageOrbisOption = React.useMemo(
    (): DropdownOption<keyof typeof LanguagesOrbis> | undefined =>
      languageOrbisOptions.find(
        (o) => o.value === mapStyleSettings.languageOrbis,
      ),
    [mapStyleSettings.languageOrbis],
  );

  const isLatinScriptDisabled = React.useMemo(
    (): boolean => !isLatinAvailable(mapStyleSettings.languageGenesis),
    [mapStyleSettings.languageGenesis],
  );

  // Wrapper to keep the menu open once the map unmounts and mounts after style change.
  const handleSettingsChange = (partial: Partial<MapStyleSettingsState>) => {
    setMapStyleSettings({
      ...partial,
      defaultMenuOpen: true,
    });
  };

  return (
    <MapStylesMenuContainer>
      <Heading level={6}>Map styles</Heading>
      <StylesContainer>
        {styleEntries.map((styleEntry) => (
          <div key={styleEntry.title}>
            <Thumbnail
              src={styleEntry.image}
              alt={styleEntry.title + ' thumbnail'}
              onClick={() => handleSettingsChange({ style: styleEntry.title })}
              className={
                mapStyleSettings.style === styleEntry.title
                  ? 'selected'
                  : undefined
              }
            />
            <MapStyleLabel $color={tombac.color('neutral', 700)}>
              {styleEntry.title}
            </MapStyleLabel>
          </div>
        ))}
      </StylesContainer>
      <MapMenuToggle
        label="Points of Interest (POI)"
        checked={mapStyleSettings.basicPOI}
        onChange={() =>
          handleSettingsChange({ basicPOI: !mapStyleSettings.basicPOI })
        }
        disabled={
          mapTypeName === 'Genesis' &&
          (mapStyleSettings.style === 'Mono light' ||
            mapStyleSettings.style === 'Mono dark')
        }
      />
      <div>
        <SelectFormGroup label="Language script" $width="100%">
          {/* Once the app switches to orbis fully, this duplication of code will be removed */}
          {mapTypeName === 'Orbis' ? (
            <Select<DropdownOption<keyof typeof LanguagesOrbis>>
              options={languageOrbisOptions}
              size="s"
              $width="188px"
              value={selectedLanguageOrbisOption}
              onChange={(
                option:
                  | DropdownOption<keyof typeof LanguagesOrbis>
                  | undefined
                  | null,
              ) => {
                handleSettingsChange({ languageOrbis: option?.value ?? 'ngt' });
              }}
              closeMenuOnSelect={false}
            />
          ) : (
            <Select<DropdownOption<keyof typeof LanguagesGenesis>>
              options={languageGenesisOptions}
              size="s"
              $width="188px"
              value={selectedLanguageGenesisOption}
              onChange={(
                option:
                  | DropdownOption<keyof typeof LanguagesGenesis>
                  | undefined
                  | null,
              ) => {
                handleSettingsChange({
                  languageGenesis: option?.value ?? 'ngt',
                });
              }}
              closeMenuOnSelect={false}
            />
          )}
        </SelectFormGroup>
      </div>
      <MapMenuToggle
        label="Latin script"
        checked={mapStyleSettings.latin}
        onChange={() =>
          handleSettingsChange({ latin: !mapStyleSettings.latin })
        }
        disabled={isLatinScriptDisabled}
      />
    </MapStylesMenuContainer>
  );
};
