import { centerOnRegions } from 'components/Map/mapUtils';
import { useEffect, useRef } from 'react';
import { useAnalysisContext } from '../AnalysisViewPage';

const pauseFor = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const useCenterRegions = (onMapReady?: VoidFunction) => {
  const mapbox = useRef<mapboxgl.Map | undefined>(undefined);
  const { analysis } = useAnalysisContext();

  useEffect(() => {
    if (!mapbox.current) {
      return;
    }
    let current = true;
    const center = async () => {
      centerOnRegions(mapbox.current, analysis.regions);
      await pauseFor(100);
      if (current && onMapReady) {
        onMapReady();
      }
    };
    center();
    return () => {
      current = false;
    };
  }, [analysis.regions, onMapReady]);

  return mapbox;
};
