import { bbox, Geometry, GeometryCollection } from '@turf/turf';
import { Analysis } from 'model/AnalysisDto';
import { MapTypes } from 'reducers/menuReducer';

export const mapboxBbox = (
  geometry: Geometry | GeometryCollection,
): [number, number, number, number] =>
  bbox(geometry) as [number, number, number, number];

export const analysisToMapTypeName = (analysis: Analysis): MapTypes => {
  if (analysis.info.map?.type.toUpperCase().includes('OPEN_DSEG')) {
    return 'Orbis';
  }
  return 'Genesis';
};
