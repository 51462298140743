import LimitsApi from 'api/LimitsApi';
import { calculateArea } from 'components/AnalysisNew/RegionSelection/areaValidation';
import { useState, useEffect, useMemo } from 'react';
import { RegionDto } from 'model/RegionDto';
import RoadCoverageApi from 'api/RoadCoverageApi';
import JobStatsApi from 'api/JobStatsApi';
import { AnalysisType } from 'model/AnalysisDto';

export const DEFAULT_SL_REGION_BUFFER_IN_KM = 0;

interface Params {
  regions: RegionDto[];
  type: AnalysisType;
  analysisId?: number;
  mapVersion?: string;
  mapType?: string;
  bufferRadiusInKilometers?: number;
  enabled?: boolean;
}

interface Result {
  coverage: number;
  loading: boolean;
  error: Error;
  limitExceeded: boolean;
}

export const useRoadCoverage = ({
  regions,
  analysisId,
  type,
  mapVersion,
  mapType,
  bufferRadiusInKilometers,
  enabled = true,
}: Params): Result => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [coverage, setCoverage] = useState<number>();

  const regionsArea = useMemo(() => calculateArea(regions), [regions]);
  const limits = LimitsApi.use();
  const areaLimitNotExceeded =
    limits === undefined || regionsArea <= limits.limits.maxAreaSize;

  const correctBufferRadiusInKilometers = bufferRadiusInKilometers;

  useEffect(() => {
    if (enabled) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const fetchData = async () => {
        setError(undefined);
        setIsLoading(true);
        try {
          let data;
          if (analysisId !== undefined) {
            data = await JobStatsApi.getStats(type, analysisId);
          } else if (areaLimitNotExceeded) {
            data = await RoadCoverageApi.getLength(
              regions,
              signal,
              mapType,
              mapVersion,
              correctBufferRadiusInKilometers,
            );
          } else {
            setError(new Error('Area limit exceeded'));
          }

          if (!signal.aborted) {
            setCoverage(data.lengthInKm ?? data.roadCoverage);
            setIsLoading(false);
          }
        } catch (e) {
          if (!signal.aborted) {
            setError(e);
          }
        }
      };

      if (regions.length === 0) {
        setCoverage(undefined);
        setError(undefined);
        return;
      }

      fetchData();

      return () => {
        abortController.abort();
      };
    }
  }, [regions, enabled, bufferRadiusInKilometers, mapType, mapVersion]);

  return {
    coverage,
    loading: isLoading,
    error,
    limitExceeded: !areaLimitNotExceeded,
  };
};
