import React from 'react';
import AnalysisApi from 'api/AnalysisApi';
import { MapTypes } from 'reducers/menuReducer';
import AnalysisCancelationModal from 'components/AnalysisTable/AnalysisCancelationModal';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import RenameRegions from 'components/AnalysisViewPage/RenameRegions/RenameRegions';
import AnalysisRename from '../AnalysisRename';
import AnalysisSummary from '../AnalysisSummary/AnalysisSummary';
import { analysisToMapTypeName } from 'components/AnalysisViewPage/utils';
import {
  AnalysisMapModalActions,
  AnalysisMapModalState,
} from './analysisMap.types';

interface Props {
  modalState: AnalysisMapModalState;
  modalActions: AnalysisMapModalActions;
}

export const AnalysisMapModalContent = ({
  modalState,
  modalActions,
}: Props) => {
  const { analysis, refresh } = useAnalysisContext();
  const mapTypeName = React.useMemo(
    (): MapTypes => analysisToMapTypeName(analysis),
    [analysis],
  );
  const { name, id } = analysis.info;
  const cancel = async () => {
    await AnalysisApi.cancel(analysis.info.id);
    modalActions.closeModal();
    refresh();
  };
  switch (modalState) {
    case 'CLOSED':
      return null;
    case 'CANCEL': {
      return (
        <AnalysisCancelationModal
          onClose={modalActions.closeModal}
          onCancel={cancel}
          analysisName={name}
          analysisId={id}
        />
      );
    }
    case 'RENAMING': {
      return (
        <AnalysisRename
          name={name}
          analysisId={id}
          onClose={modalActions.closeModal}
        />
      );
    }
    case 'RENAMING_REGIONS': {
      return (
        <RenameRegions
          onClose={modalActions.closeModal}
          mapTypeName={mapTypeName}
        />
      );
    }
    case 'SUMMARY_ONLY': {
      return (
        <AnalysisSummary
          regions={analysis.regions}
          onClose={modalActions.closeModal}
        />
      );
    }
    case 'SUMMARY_WITH_ACTIONS': {
      return (
        <AnalysisSummary
          regions={analysis.regions}
          onClose={modalActions.closeModal}
          showActions
        />
      );
    }
  }
};
