import React, { useMemo, useState } from 'react';
import { Modal } from 'tombac';
import { MapTypes } from 'reducers/menuReducer';
import { SelectedLinkFeature } from 'components/AnalysisNew/SelectedLink/SelectedLinkFeature';
import Title from 'components/Layout/Title';
import GlMap from '../../Map/GlMap';
import { MapControls } from '../../Map/MapControls';
import { DisplayRegions } from '../../Map/Layers/DisplayRegions';
import { useAnalysisContext } from '../AnalysisViewPage';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './AnalysisMap.css';
import { AnalysisMapSidebar } from './AnalysisMapSidebar/AnalysisMapSidebar';
import { AnalysisType } from 'model/AnalysisDto';
import { useCenterRegions } from './useCenterRegions';
import {
  AnalysisMapModalActions,
  AnalysisMapModalState,
} from './AnalysisMapSidebar/analysisMap.types';
import { AnalysisMapModalContent } from './AnalysisMapSidebar/AnalysisMapModalContent';
import { isSelectedLinkRegion } from 'components/SelectedLinkPage/utils';
import { MapMenuToggle } from 'components/UI/MapMenu/MapMenuToggle';
import { analysisToMapTypeName } from '../utils';
import { isRegionAnalysis } from 'logic/analysis/analysisUtils';

export const AnalysisMap: React.FC = () => {
  const { analysis } = useAnalysisContext();
  const [regionNames, setRegionNames] = useState(true);
  const [modalState, setModalState] = useState<AnalysisMapModalState>('CLOSED');
  const mapbox = useCenterRegions();
  const modalActions = useMemo<AnalysisMapModalActions>(
    () => ({
      closeModal: () => setModalState('CLOSED'),
      openRenamingModal: () => setModalState('RENAMING'),
      openRenamingRegionsModal: () => setModalState('RENAMING_REGIONS'),
      openCancelModal: () => setModalState('CANCEL'),
      openSummaryWithActionsModal: () => setModalState('SUMMARY_WITH_ACTIONS'),
      openSummaryModal: () => setModalState('SUMMARY_ONLY'),
    }),
    [],
  );

  const { type, id } = analysis.info;
  const regions = analysis.regions;

  const isShared = location.href.includes('/share/');

  const mapTypeName = React.useMemo(
    (): MapTypes => analysisToMapTypeName(analysis),
    [analysis],
  );

  const handleRegionNamesCheckedChange = React.useCallback(
    () => setRegionNames((prev) => !prev),
    [],
  );

  return (
    <>
      <Title>Analysis</Title>{' '}
      <ViewPageContent>
        <AnalysisMapSidebar isShared={isShared} modalActions={modalActions} />
        <GlMap
          controlLocation="top-right"
          mapboxRef={(c: any) => (mapbox.current = c)}
          mapTypeName={mapTypeName}
        >
          <MapControls
            map={mapbox}
            mapTypeName={mapTypeName}
            mapStyleMenuContent={
              <MapMenuToggle
                label="Region names"
                checked={regionNames}
                onChange={handleRegionNamesCheckedChange}
              />
            }
            zoomInButton={{
              type: type === AnalysisType.SelectedLink ? 'link' : 'regions',
              regions,
            }}
          />
          {type === AnalysisType.SelectedLink &&
            (isSelectedLinkRegion(analysis) ? (
              <DisplayRegions dtoRegions={regions} layerId="selected-link" />
            ) : (
              <SelectedLinkFeature links={regions} />
            ))}
          {isRegionAnalysis(type) && (
            <DisplayRegions
              dtoRegions={regions}
              regionNames={regionNames}
              layerId="flow-matrix"
            />
          )}
        </GlMap>
      </ViewPageContent>
      <Modal
        isOpen={modalState !== 'CLOSED'}
        onRequestClose={modalActions.closeModal}
        className={`AnalysisMap__Modal ${modalState} `}
      >
        <AnalysisMapModalContent
          modalActions={modalActions}
          modalState={modalState}
        />
      </Modal>
    </>
  );
};
