import React from 'react';
import { Text, Popover, Button, tombac } from 'tombac';
import styled from 'styled-components';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { useMapStyleSettings } from 'reducers/mapStyleSettingsReducer';

const NewBadge = styled.div`
  height: ${tombac.space(2.5)};
  width: ${tombac.space(5.5)};
  display: inline-flex;
  position: absolute;
  top: -10px;
  left: -24px;
  align-items: center;
  justify-content: center;
  padding: ${tombac.space(0.25, 0.5)};
  border-radius: 4px;
  background: #00aaff;
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 16px;
  z-index: 3;
`;

const IconButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  background-color: white;
  & > span {
    margin-top: 4px;
  }
  &:hover {
    background-color: #F2F2F2;
  }
`;

const text = 'Map layers';

interface Props {
  icon: string;
  children?: React.ReactElement | React.ReactElement[];
}

const MapMenuButton: React.FC<Props> = ({ icon, children }) => {
  const [mapStyleSettings, setMapStyleSettings] = useMapStyleSettings();

  const handlePopoverOpenChange = React.useCallback((open: boolean) => {
    if (!open) {
      setTimeout(() => {
        setMapStyleSettings({
          defaultMenuOpen: false,
        });
      }, 200);
    }
  }, []);

  return (
    <Popover
      placement="left top"
      defaultOpen={mapStyleSettings.defaultMenuOpen}
      $overflowX="hidden"
      $overflowY="auto"
      $display="grid"
      $gridTemplateColumns="1"
      $gridRowGap="10px"
      onOpenChange={handlePopoverOpenChange}
      trigger={({ triggerProps }) => (
        <SimpleTooltip
          content={
            <Text $color="#ffffff" style={{ width: 'max-content' }}>
              {text}
            </Text>
          }
          placement="auto"
        >
          <>
            <NewBadge>NEW</NewBadge>
            <IconButton {...triggerProps}>
              <img src={icon} alt={text} />
            </IconButton>
          </>
        </SimpleTooltip>
      )}
    >
      {children}
    </Popover>
  );
};

export default MapMenuButton;
