import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useQuery } from 'react-query';
import AnalysisApi from 'api/AnalysisApi';
import { SelectedLinkPage } from 'components/SelectedLinkPage/SelectedLinkPage';
import { Loading } from 'components/UI/Loading';
import { queryClient } from 'legoland-sdk/dist/experimental';
import { Analysis, AnalysisStatus, AnalysisType } from 'model/AnalysisDto';
import { RegionDto } from 'model/RegionDto';

import { MapFlowsPro } from '../Layout/BasePage';
import './AnalysisViewPage.css';
import ColoredMatrix from './ColoredMatrix/ColoredMatrix';
import { AnalysisMap } from './Map/AnalysisMap';
import { MapFlows } from './MapFlows/MapFlows';
import NavBar from './NavBar/NavBar';
import NavBarTitle from './NavBar/NavBarTitle';
import SankeyDiagram from './SankeyDiagram/SankeyDiagram';
import { SpatialSankey } from './SpatialSankey/SpatialSankey';
import ViewPageContent from './ViewPageContent/ViewPageContent';
import { FlowLinksResults } from './FlowLinks/FlowLinksResults';

const getUrlParams = () => {
  const search = location.search.substring(1);
  return JSON.parse(
    '{"' + decodeURI(search.replace(/&/g, '","').replace(/=/g, '":"')) + '"}',
  );
};

interface Props {
  match: any;
  getAnalysis: any;
}

const useAnalysis = (id: number, type: AnalysisType, sharedKey?: string) => {
  const { data: analysis, status, error } = useQuery(
    [id, sharedKey],
    () => AnalysisApi.get(id, type, sharedKey),
    {
      staleTime: Infinity,
      retry: 1,
    },
  );
  const refresh = useCallback(() => {
    queryClient.refetchQueries([id, sharedKey]);
  }, [id, sharedKey]);

  if (status === 'error' && error) {
    throw error;
  }

  return {
    analysis,
    status,
    refresh,
  };
};

interface AnalysisContext {
  refresh: (force?: boolean) => void;
  analysis: Analysis;
}
const AnalysisContext = createContext<AnalysisContext>(undefined);
export const useAnalysisContext = () => useContext(AnalysisContext);

export const useRegions = () => {
  const { analysis } = useAnalysisContext();
  const params = analysis.info;

  return useMemo((): RegionDto[] => {
    const hasExternals = params.passMatrix === false;

    return hasExternals
      ? [
          ...analysis.regions,
          {
            properties: { name: 'External', i: analysis.regions.length } as any,
          } as RegionDto,
        ]
      : analysis.regions;
  }, [analysis.regions]);
};

const AnalysisViewPage: React.FC<Props> = (props) => {
  const sharedKey = (() => {
    if (props.match.url.includes('/share/')) {
      const params = getUrlParams();
      return params.t;
    }
    return;
  })();

  const analysisType = () => {
    if (props.match.url.includes('/flow-links/')) {
      return AnalysisType.FlowLinks;
    } else if (props.match.url.includes('/selected-link/')) {
      return AnalysisType.SelectedLink;
    } else {
      return AnalysisType.FlowMatrix;
    }
  };
  const { analysis, status, refresh } = useAnalysis(
    props.match.params.analysisId,
    analysisType(),
    sharedKey,
  );
  const warmedUp = useRef(false);
  useEffect(() => {
    if (status !== 'success' || warmedUp.current) {
      return;
    }
    const { dateRanges, timeRanges } = analysis.info.timeDefinition;
    AnalysisApi.getResult(analysis, dateRanges[0], timeRanges[0], {
      origins: [0],
      destinations: [0],
      vias: [0],
    }).catch(() => {});
    warmedUp.current = true;
  }, []);

  useEffect(() => {
    const loop = () => {
      const needToRefresh = [AnalysisStatus.RUNNING, AnalysisStatus.WAITING];
      if (
        needToRefresh.includes(analysis?.info?.status) &&
        status === 'success'
      ) {
        refresh();
      }
    };

    const handle = setInterval(loop, 5000);
    loop();

    return () => {
      clearInterval(handle);
    };
  }, [analysis?.info?.status, status]);

  if (
    status === 'loading' ||
    status === 'idle' ||
    analysis.info.id !== props.match.params.analysisId
  ) {
    return (
      <div id="view-page">
        <NavBar>
          <NavBarTitle>Loading Analysis</NavBarTitle>
        </NavBar>
        <ViewPageContent style={{ backgroundColor: '#FFF' }}>
          <div className="view-page-preloader">
            <Loading />
          </div>
        </ViewPageContent>
      </div>
    );
  }

  return (
    <AnalysisContext.Provider
      value={{
        refresh,
        analysis,
      }}
    >
      <div id="view-page">
        <Switch>
          <Route exact path={`${props.match.path}/`} component={AnalysisMap} />
          <Route
            exact
            path={`${props.match.path}/map-flows`}
            component={MapFlows}
          />
          <Route
            exact
            path={`${props.match.path}/colored-matrix`}
            component={ColoredMatrix}
          />
          <Route
            exact
            path={`${props.match.path}/sankey`}
            component={SankeyDiagram}
          />
          <Route
            exact
            path={`${props.match.path}/spatial-sankey`}
            component={SpatialSankey}
          />
          <Route
            exact
            path={`${props.match.path}/mfp`}
            component={MapFlowsPro}
          />
          <Route
            exact
            path={`${props.match.path}/selected-link`}
            component={SelectedLinkPage}
          />
          <Route
            exact
            path={`${props.match.path}/results`}
            component={FlowLinksResults}
          />
          <Redirect to={`${props.match.url}/`} />
        </Switch>
      </div>
    </AnalysisContext.Provider>
  );
};

export default AnalysisViewPage;
