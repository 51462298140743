import React from 'react';
import DisplayGeojson, { Props } from './DisplayGeojson';
import { useMapStyleSettings } from 'reducers/mapStyleSettingsReducer';

export const DisplayRegions: React.FC<Omit<Props, 'map'>> = (props) => {
  const [
    _mapStyleSettings,
    _setMapStyleSettings,
    { mapStyleMode },
  ] = useMapStyleSettings();

  return <DisplayGeojson mapStyleMode={mapStyleMode} {...props} />;
};
