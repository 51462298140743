import { Feature, FeatureCollection } from '@turf/helpers';
import { MapContext } from 'components/Map/GlMap';
import { GeoJSONSource, Layer } from 'mapbox-gl';
import { useContext, useEffect } from 'react';

const empty: any = {
  type: 'FeatureCollection',
  features: [],
};

const parseData = (data: any): GeoJSON.FeatureCollection => {
  let parsed;
  if (data === undefined) {
    parsed = empty;
  } else if (data instanceof Array) {
    parsed = { type: 'FeatureCollection', features: data };
  } else {
    parsed = data;
  }
  return parsed;
};

const useLayers = (
  id: string,
  layers: Layer[],
  data: FeatureCollection | Feature | Feature[] | undefined,
  beforeLayer?: string,
  generateId = false,
) => {
  const map: mapboxgl.Map = useContext(MapContext);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: parseData(data),
      generateId,
    });
    layers.forEach((l) =>
      map.addLayer(
        { ...l, source: id } as any,
        map.getLayer(beforeLayer) ? beforeLayer : undefined,
      ),
    );

    return () => {
      layers.forEach((l) => {
        if (map.getLayer(l.id)) {
          map.removeLayer(l.id);
        }
      });
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [id, layers, beforeLayer]);

  useEffect(() => {
    const source = map.getSource(id) as GeoJSONSource;

    source.setData(parseData(data));
  }, [data]);
};

export default useLayers;
