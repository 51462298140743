import React from 'react';
import { Checkbox, Text } from 'tombac';
import styled from 'styled-components';

const BoldText = styled(Text)`
  font-weight: bold;
  &.disabled {
    color: #bebebe;
    cursor: not-allowed;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Toggle = styled(Checkbox)`
  &> * {
    margin-left: auto;
  }
  &.disabled {
    cursor: not-allowed;
  }
`;

interface Props {
  label: string | React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

export const MapMenuToggle: React.FC<Props> = ({ label, checked, disabled, onChange }) => {
  return (
    <ToggleContainer>
      <BoldText className={disabled ? "disabled" : undefined}>{label}</BoldText>
      <Toggle
        checked={checked}
        labelPlacement="before"
        label=""
        size="xs"
        variant="toggle"
        onChange={!disabled ? onChange : undefined}
        disabled={disabled}
        className={disabled ? 'disabled' : undefined}
      />
    </ToggleContainer>
  );
};
