import * as React from 'react';
import Tooltip from './Tooltip';
import { PLACEMENTS } from './constants';

export interface TooltipManagerProps {
  content: (controlFns: {
    open: () => void;
    close: () => void;
    toggle: () => void;
  }) => any;
  children: (controlFns: {
    open: () => void;
    close: () => void;
    toggle: () => void;
  }) => any;
  onOpen?: () => void;
  onClose?: () => void;
  ignoreOutsideClick?: boolean;
  placement: PLACEMENTS;
  className?: string;
  [prop: string]: any;
}

export interface TooltipManagerState {
  isActive: boolean;
  offsetPoint?: any;
}

class TooltipManager extends React.PureComponent<
  TooltipManagerProps,
  TooltipManagerState
> {
  static defaultProps = {
    className: '',
    onOpen: () => {},
    onClose: () => {},
  };

  triggerEl;

  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      offsetPoint: undefined,
    };

    this.openTooltip = this.openTooltip.bind(this);
    this.closeTooltip = this.closeTooltip.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  openTooltip() {
    this.toggleTooltip(true);
  }

  closeTooltip() {
    this.toggleTooltip(false);
  }

  toggleTooltip(value = null) {
    this.setState(({ isActive }) => ({
      isActive: value !== null ? value : !isActive,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      const callback = this.state.isActive
        ? this.props.onOpen
        : this.props.onClose;
      callback();
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleOutsideClick() {
    if (!this.props.ignoreOutsideClick) {
      this.closeTooltip();
    }
  }

  render() {
    const { children, content, ...tooltipProps } = this.props;

    const controlFns = {
      open: this.openTooltip,
      close: this.closeTooltip,
      toggle: this.toggleTooltip,
    };
    const triggerContent = React.cloneElement(children(controlFns), {
      ref: (node) => (this.triggerEl = node),
    });

    return (
      <React.Fragment>
        {triggerContent}

        {this.state.isActive && (
          <Tooltip
            {...tooltipProps}
            refElement={this.triggerEl}
            offsetPoint={this.state.offsetPoint}
            onClickOutside={this.handleOutsideClick}
            content={() => content(controlFns)}
          />
        )}
      </React.Fragment>
    );
  }
}

export default TooltipManager;
