import mapboxgl from 'mapbox-gl';
import { LanguagesOrbis, supportedLanguagesOrbis } from 'model/MapStyle';
import { MapStyleSettingsState } from 'reducers/mapStyleSettingsReducer';
import { MapTypes } from 'reducers/menuReducer';

export const SOURCE_ID = 'vectorTiles';
export const SOURCE_ID_JAPAN = 'vectorTilesJapan';
const LAYER_ID_BACKGROUND = 'background';
const GROUP_ID_SATELLITE = 'satellite';

interface FeatureState {
  sourceId: string;
  featureId: string | number;
  state: { [key in string]: any };
}

interface MapCache {
  baseMapSourceIds: string[];
  baseMapLayerIds: string[];
  nonBaseMapSources: [string, mapboxgl.AnySourceData][];
  nonBaseMapLayers: mapboxgl.AnyLayer[];
  featuresState: FeatureState[];
}

const GENESIS_STYLE_VERSION = '25.*';
const ORBIS_STYLE_VERSION = '0.*';
const POI_GROUP_ID = 'POI';

const styleToStyleJsonMap: {
  [key in MapStyleSettingsState['style']]: string;
} = {
  'Street light': 'basic_street-light',
  'Street dark': 'basic_street-dark',
  'Mono light': 'basic_mono-light',
  'Mono dark': 'basic_mono-dark',
  Satellite: 'basic_street-satellite',
};

/**
 * Replaces the {name} and {sub_text} template strings that appear in the TomTom provided styles.
 */
const replaceTemplateStrings = (body: string, language: string) => {
  body = body.replaceAll(
    '"{name}\n{sub_text}"',
    `[
      "concat",
      [
          "coalesce",
          [
              "get",
              "name_${language}"
          ],
          [
              "get",
              "name"
          ]
      ],
      "\n",
      [
          "get",
          "sub_text"
      ]
  ]`,
  );

  body = body.replaceAll(
    '"{name}"',
    `[
      "coalesce",
      [
          "get",
          "name_${language}"
      ],
      [
          "get",
          "name"
      ]
  ]`,
  );

  return body;
};

/**
 * Fix a bug in the current TomTom style document where there is a %% instead of %.
 */
const fixHSLColors = (body: string) => {
  body = body.replaceAll('%%', '%');
  body = body.replaceAll('hsla(0, 0, 0, 0)', 'hsla(0, 0%, 0%, 0)');

  return body;
};

export const isLatinAvailable = (
  language:
    | MapStyleSettingsState['languageGenesis']
    | MapStyleSettingsState['languageOrbis'],
) => {
  return (
    language === 'ngt' ||
    language === 'ru-RU' ||
    language === 'ru' ||
    language === 'ko' ||
    language === 'uk' ||
    language === 'sr'
  );
};

/**
 * Fetches the TomTom Map Style and adds necessary adjustments (for example for language) based on provided settings.
 * @param settings
 * @returns
 */
const loadStyle = async (
  settings: MapStyleSettingsState,
  mapTypeName: MapTypes,
): Promise<mapboxgl.Style> => {
  let templateUrl: string;
  const style = settings.style;
  const styleJson =
    styleToStyleJsonMap[style] ?? styleToStyleJsonMap['Street light'];
  const baseUrl = 'https://api.tomtom.com';
  const apiKey = '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv';
  const templateUrlGenesis = `${baseUrl}/style/1/style/${GENESIS_STYLE_VERSION}?map=2/${styleJson}`;
  const templateUrlOrbis = `${baseUrl}/maps/orbis/assets/styles/${ORBIS_STYLE_VERSION}/style.json?apiVersion=1&sourcesVersion=1&map=${styleJson}`;
  templateUrl = `${
    mapTypeName === 'Orbis' ? templateUrlOrbis : templateUrlGenesis
  }&key=${apiKey}`;
  let body = '';

  const response = await fetch(templateUrl, { cache: 'no-cache' });
  if (!response.ok) {
    throw new Error(
      `Unprocessable resource version: ${GENESIS_STYLE_VERSION}.`,
    );
  }

  body = await response.text();

  let language = '';
  const settingsLanguage =
    mapTypeName === 'Orbis' ? settings.languageOrbis : settings.languageGenesis;
  if (settings.latin && isLatinAvailable(settingsLanguage)) {
    if (settingsLanguage === 'ru-RU') {
      language = 'ru-Latn-RU';
    } else {
      language = `${settingsLanguage}-Latn`;
    }
  } else {
    language = settingsLanguage;
  }

  body = replaceTemplateStrings(body, language);
  body = fixHSLColors(body);

  const mapJSON = JSON.parse(body) as mapboxgl.Style;

  // Genesis mono does not contain many POIs in the base layer by design. Option to enable basic POI is therefore disabled.
  const isGenesisMono =
    mapTypeName === 'Genesis' &&
    (settings.style === 'Mono light' || settings.style === 'Mono dark');

  if (!settings.basicPOI || isGenesisMono) {
    mapJSON.layers = (mapJSON.layers ?? []).filter(
      (layer) =>
        layer.type === 'custom' || layer.metadata?.group !== POI_GROUP_ID,
    );
  }

  if (mapJSON.sources) {
    for (const key of Object.keys(mapJSON.sources)) {
      const source = mapJSON.sources[key];
      if (source.type === 'vector') {
        if (source.url) {
          const url = new URL(source.url);
          source.url = decodeURI(url.toString());
        }
        if (source.tiles) {
          source.tiles = source.tiles.map((tile) => {
            const url = new URL(tile);
            return decodeURI(url.toString());
          });
        }
      }
    }
  }

  return mapJSON;
};

/**
 * Layer order is affected when style is replaced.
 * This object keeps cache of all other sources and layers than thos of the base map.
 * It is used to combine them with the base map on each map style update.
 */
const currentMapCache: MapCache = {
  baseMapSourceIds: [],
  baseMapLayerIds: [],
  nonBaseMapSources: [],
  nonBaseMapLayers: [],
  featuresState: [],
};

/**
 * Replaces just the base map sources and layers and makes sure all other layers and sources are added on top of them.
 * @param map
 * @param settings
 */
export const getMapboxStyle = async (
  map: mapboxgl.Map,
  settings: MapStyleSettingsState,
  mapTypeName: MapTypes,
) => {
  const mapboxStyle = await loadStyle(settings, mapTypeName);
  const mapboxStyleJapan = await loadStyle(
    {
      ...settings,
      languageOrbis: supportedLanguagesOrbis.includes(
        settings.languageGenesis as keyof typeof LanguagesOrbis,
      )
        ? (settings.languageGenesis as keyof typeof LanguagesOrbis)
        : 'ngt',
    },
    'Orbis',
  );

  if (mapTypeName === 'Genesis') {
    if (!mapboxStyle.sources) {
      mapboxStyle.sources = {};
    }
    if (mapboxStyleJapan.sources?.[SOURCE_ID]) {
      mapboxStyle.sources[SOURCE_ID_JAPAN] =
        mapboxStyleJapan.sources[SOURCE_ID];
    }
    mapboxStyle.layers = [
      ...(mapboxStyleJapan.layers ?? []).map((layer) => {
        if (layer.type !== 'custom' && layer.source === SOURCE_ID) {
          layer.source = SOURCE_ID_JAPAN;
        }
        if (layer.type !== 'custom' && layer.id !== LAYER_ID_BACKGROUND) {
          layer.id = layer.id + '-jpn';
        }
        return layer;
      }),
      ...(mapboxStyle.layers ?? [])
        // Those groups are added by the Japan (Orbis) tiles before, need to be filtered out here in order not to cover Japan tiles.
        .filter(
          (l) =>
            l.type === 'custom' ||
            (l.id !== LAYER_ID_BACKGROUND &&
              l.metadata.group !== GROUP_ID_SATELLITE),
        ),
    ];
  }

  const updateBaseMapCache = () => {
    const { layers: currentLayers, sources } = map.getStyle();
    const currentSources = sources ? Object.entries(sources) : [];

    currentMapCache.nonBaseMapSources = currentSources.filter(
      ([id]) =>
        !currentMapCache.baseMapSourceIds.includes(id) &&
        !Object.keys(mapboxStyleJapan.sources ?? {}).includes(id),
    );
    currentMapCache.nonBaseMapLayers = (currentLayers ?? []).filter(
      ({ id }) =>
        !currentMapCache.baseMapLayerIds.includes(id) &&
        !mapboxStyleJapan.layers?.some((l) => l.id === id),
    );
    currentMapCache.baseMapSourceIds = Object.keys(mapboxStyle.sources ?? {});
    currentMapCache.baseMapLayerIds = (mapboxStyle.layers ?? []).map(
      (l) => l.id,
    );

    const featuresState: FeatureState[] = [];

    for (const [sourceId, source] of currentMapCache.nonBaseMapSources) {
      if (
        source.type !== 'geojson' ||
        typeof source.data !== 'object' ||
        source.data.type !== 'FeatureCollection'
      ) {
        continue;
      }
      for (const feature of source.data.features) {
        featuresState.push({
          sourceId,
          featureId: feature.id,
          state: map.getFeatureState({ source: sourceId, id: feature.id }),
        });
      }
    }

    currentMapCache.featuresState = featuresState;
  };

  updateBaseMapCache();

  return mapboxStyle;
};

/**
 * Removes current base map sources and layers one by one and adds new ones without reloading the whole style
 * in order to keep the other sources, layers and images as they are, on top of the base layers.
 * @param map
 * @param settings
 */
export const updateStyle = async (
  map: mapboxgl.Map,
  settings: MapStyleSettingsState,
  mapTypeName: MapTypes,
): Promise<void> => {
  const oldBaseMapSourceIds = [...currentMapCache.baseMapSourceIds];
  const oldBaseMapLayerIds = [...currentMapCache.baseMapLayerIds];
  const mapboxStyle = await getMapboxStyle(map, settings, mapTypeName);
  for (const layerId of oldBaseMapLayerIds) {
    map.removeLayer(layerId);
  }
  for (const sourceId of oldBaseMapSourceIds) {
    map.removeSource(sourceId);
  }
  for (const [sourceId, source] of Object.entries(mapboxStyle.sources ?? {})) {
    map.addSource(sourceId, source);
  }
  let previousId = currentMapCache.nonBaseMapLayers[0]?.id;
  for (const layer of [...(mapboxStyle.layers ?? [])].reverse()) {
    map.addLayer(layer, map.getLayer(previousId) ? previousId : undefined);
    previousId = layer.id;
  }

  map.setStyle({
    ...map.getStyle(),
    glyphs: mapboxStyle.glyphs,
    sprite: mapboxStyle.sprite,
  });

  await waitForStyleLoaded(map);

  for (const { sourceId, featureId, state } of currentMapCache.featuresState) {
    map.setFeatureState({ source: sourceId, id: featureId }, state);
  }
};

// Bug with style is not done loading...
export const waitForStyleLoaded = (map: mapboxgl.Map): Promise<void> => {
  return new Promise((resolve, reject) => {
    const maxRetry = 10;
    let retryIndex = 0;
    const retryDelay = 200;
    const finishLoading = () => {
      if (map.isStyleLoaded()) {
        resolve();
      } else {
        setTimeout(() => {
          if (retryIndex < maxRetry) {
            retryIndex++;
            console.warn(`Finish loading style attempt #${retryIndex}`);
            finishLoading();
          } else {
            reject(new Error('Style load check timeout exceeded'));
          }
        }, retryDelay);
      }
    };

    finishLoading();
  });
};
