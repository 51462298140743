import React from 'react';
import { FormGroup, Text } from 'tombac';
import { MapTypes } from 'reducers/menuReducer';
import { RegionDto } from '../../../model/RegionDto';
import MapScreenshot from '../../Map/MapScreenshot';
import { MapControls } from '../../Map/MapControls';
import { MapMenuToggle } from 'components/UI/MapMenu/MapMenuToggle';

interface Props {
  mapRef: React.MutableRefObject<mapboxgl.Map>;
  mapTypeName: MapTypes;
  regions: RegionDto[];
  carsPerKm: boolean;
  setCarsPerKm: (value: React.SetStateAction<boolean>) => void;
  regionNames: boolean;
  setRegionNames: (value: React.SetStateAction<boolean>) => void;
  filter: number;
  setFilter: (value: React.SetStateAction<number>) => void;
}

export const SpatialSankeyMapControls: React.FC<Props> = ({
  mapRef,
  mapTypeName,
  regions,
  carsPerKm,
  setCarsPerKm,
  regionNames,
  setRegionNames,
  filter,
  setFilter,
}) => {
  return (
    <MapControls
      map={mapRef}
      mapTypeName={mapTypeName}
      zoomInButton={{ type: 'regions', regions: regions }}
      mapStyleMenuContent={
        <>
          <MapMenuToggle
            label="Flows/km²"
            checked={carsPerKm}
            onChange={() => setCarsPerKm(!carsPerKm)}
          />
          <MapMenuToggle
            label="Region names"
            checked={regionNames}
            onChange={() => setRegionNames(!regionNames)}
          />
          <FormGroup label="Lower limit">
            <Text>{filter}%</Text>
            <input
              type="range"
              min={0}
              max={100}
              value={filter}
              onChange={(e) => setFilter(Number(e.target.value))}
            />
          </FormGroup>
        </>
      }
    >
      <MapScreenshot map={mapRef.current} />
    </MapControls>
  );
};
