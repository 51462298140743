import { combineReducers } from 'redux';
import error, { ErrorState } from './errorReducer';
import { mapStyleSettingsSlice, MapStyleSettingsState } from './mapStyleSettingsReducer';
import { menuSlice, MenuState } from './menuReducer';
import { userSlice, UserState } from './userReducer';

export interface AppState {
  menu: MenuState;
  mapStyleSettings: MapStyleSettingsState;
  user?: UserState;
  error: ErrorState;
  templates: any;
}

const combinedReducer = combineReducers<AppState>({
  error,
  mapStyleSettings: mapStyleSettingsSlice.reducer,
  menu: menuSlice.reducer,
  user: userSlice.reducer,
});

export default combinedReducer;
