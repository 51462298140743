import React from 'react';
import { ZoomToIcon } from 'tombac-icons';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import classNames from 'classnames';
import { Text } from 'tombac';
import './ZoomInButton.css';

interface Props {
  onClick: () => void;
  position?: 'default' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  type: 'regions' | 'link';
}

const ZoomInButton: React.FC<Props> = ({ position = 'default', ...props }) => {
  const text = React.useMemo(() => `Pan to ${props.type}`, [props.type]);

  return (
    <div
      className={classNames('ZoomInButton', {
        ['ZoomInButton--default']: position === 'default',
        ['ZoomInButton--topRight']: position === 'top-right',
        ['ZoomInButton--bottomRight']: position === 'bottom-right',
        ['ZoomInButton--bottomLeft']: position === 'bottom-left',
      })}
      onClick={props.onClick}
    >
      <SimpleTooltip
        content={
          <Text $color="#ffffff" style={{ width: 'max-content' }}>
            {text}
          </Text>
        }
        placement="auto"
      >
        <ZoomToIcon />
      </SimpleTooltip>
    </div>
  );
};

export default ZoomInButton;
